import { FAQData } from "./type";

const apiKeyMetaTag = document.querySelector('meta[name="api-key"]') as HTMLMetaElement;
const apiKey = apiKeyMetaTag ? apiKeyMetaTag.content : "";

export default async function getFaq(slug: string): Promise<FAQData | undefined> {

    let url = "https://client-api.leadmetrics.ai/api/client/v1/";
    let endpoint = `faqs/${slug}`;

    let options = {
        method: 'GET',
        headers: {
            "Content-type" : "application/json",
            "Api-Key" : apiKey
        }
    }

    try {

        const response = await fetch(`${url}${endpoint}`, options);

        if(!response.ok) throw new Error("Could not fetch FAQ data"); 

        const responseData = await response.json();
        return responseData;

    } catch (error) {
        console.error(error);
    }


}
