import { FormDTO, RegexExpression } from "./type";

// Data attributes for inputs
const DATA_LM_REQUIRED = "data-lm-required";
const DATA_LM_MAX = "data-lm-max";
const DATA_LM_MIN = "data-lm-min";
const DATA_LM_COUNTRY = "data-lm-country";

// Regex for form validation
const regexExpression: RegexExpression = {
  in: {
    name: /^[a-zA-Z ]{2,30}$/,
    email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    mobile: /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/,
    whatsapp: /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/,
  },
  us: {
    mobile: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
    whatsapp: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
  },
};

// Get the API key from the meta tag
const apiKeyMetaTag = document.querySelector(
  'meta[name="api-key"]'
) as HTMLMetaElement;
const apiKey = apiKeyMetaTag ? apiKeyMetaTag.content : "";
const formUrl = "https://client-api.leadmetrics.ai/api/client/v1/forms/";

function validateForm(
  input: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement,
  form: HTMLFormElement
): boolean {
  const name = input.name;
  const value = input.value;

  const dataLmRequired = input.getAttribute(DATA_LM_REQUIRED);
  const maxAttribute = input.getAttribute(DATA_LM_MAX);
  const minAttribute = input.getAttribute(DATA_LM_MIN);
  const countryAttribute = input.getAttribute(DATA_LM_COUNTRY);

  const errorElement = form.querySelector(`#${name}Error`);

  if (errorElement) {
    // Check if error element;
    if (dataLmRequired === "true") {
      // required
      if (value === "") {
        errorElement.textContent = `Please enter your ${name}`;
        return false;
      }
    }

    if (minAttribute) {
      // min length

      const minLength = parseInt(minAttribute);

      if (value.length < minLength) {
        errorElement.textContent = `Must have minimum ${minLength} characters`;
        return false;
      }
    }

    if (maxAttribute) {
      // max length
      const maxLength = parseInt(maxAttribute);
      if (value.length > maxLength) {
        errorElement.textContent = `Can only have maximum ${maxLength} characters!`;
        return false;
      }
    }

    if (countryAttribute === "us") {
      // us country code for numbers

      const key = name as keyof RegexExpression["us"];

      if (!regexExpression.us[key].test(value)) {
        errorElement.textContent = "Please enter a valid mobile number";
        return false;
      }
    }

    if (countryAttribute === "in") {
      // in country code for numbers

      const key = name as keyof RegexExpression["in"];

      if (!regexExpression.in[key].test(value)) {
        errorElement.textContent = "Please enter a valid mobile number";
        return false;
      }
    }
    return true;
  }

  return false;
}

function getFormData(form: HTMLFormElement) {
  const formData: FormDTO = {
    name: "",
    company: "",
    city: "",
    email: "",
    mobile: "",
    whatsapp: "",
    message: "",
  };

  form.querySelectorAll("input, textarea, select").forEach((element) => {
    const input = element as
      | HTMLInputElement
      | HTMLTextAreaElement
      | HTMLSelectElement;
    const key = input.name as keyof FormDTO;

    if (input.name) formData[key] = input.value;
  });
  return formData;
}

const getUTMTags = (): Record<string, string> => {
  const params = new URLSearchParams(window.location.search);
  const utmTags: Record<string, string> = {};

  [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ].forEach((utmKey) => {
    if (params.has(utmKey)) {
      utmTags[utmKey] = params.get(utmKey) || "";
    }
  });

  return Object.keys(utmTags).length > 0 ? utmTags : {};
};

// Setup form submission with validation
function setupFormSubmission(form: HTMLFormElement, apiUrl: string): void {
  const redirectUrl = form.getAttribute("data-lm-form-redirect") || "/";

  // Declare formData outside the event listener to keep it in sync
  let formData = getFormData(form);

  form.addEventListener(
    "submit",
    async function (event: SubmitEvent): Promise<void> {
      event.preventDefault();
      let isValid = true; // Start with the assumption that the form is valid
      const errorElements = form.querySelectorAll(".error");

      errorElements.forEach((errorElement) => {
        errorElement.textContent = ""; // Clear previous error messages
      });

      // Re-fetch form data on submission
      formData = getFormData(form);

      // Perform validation for all fields, even if they are empty
      form.querySelectorAll("input, select, textarea").forEach((field) => {
        const input = field as
          | HTMLInputElement
          | HTMLTextAreaElement
          | HTMLSelectElement;
        isValid = validateForm(input, form) && isValid;
      });

      // If form is valid, proceed with submission
      if (isValid) {
        const submitBtn = form.querySelector("#submitBtn") as HTMLButtonElement;
        const originalButtonText = submitBtn.textContent;

        submitBtn.disabled = true;
        submitBtn.textContent = "Submitting...";

        const {
          name,
          email,
          mobile,
          message,
          city,
          company,
          whatsapp,
          ...otherFields
        } = formData;

        const formattedMessage = Object.entries(otherFields)
          .map(([key, value]) => `${key}: ${value}`)
          .join(", ");

        // Get UTM tags from the URL
        const utmTags = getUTMTags();

        const payload = {
          name,
          email,
          mobile,
          city,
          company,
          whatsapp,
          message: `${message ? message + ". " : ""}${formattedMessage}`,
          ...utmTags,
        };

        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Api-Key": apiKey, // Use the API key from the meta tag
            },
            body: JSON.stringify(payload),
          });
          const resData = await response.json();

          if (resData) {
            showModal(
              "Thank you!",
              "We will contact you soon...",
              redirectUrl,
              "Go to Website"
            );
            form.reset();
          }
        } catch (error) {
          console.error(error);
          showModal("Submission Failed!", "Please try again", "/", "Close");
        } finally {
          submitBtn.disabled = false;
          submitBtn.textContent = originalButtonText;
        }
      }
    }
  );

  // Add blur event listeners for validation and input handling
  form.querySelectorAll("input, textarea").forEach((field) => {
    const input = field as
      | HTMLInputElement
      | HTMLTextAreaElement
      | HTMLSelectElement;
    const key = input.name as keyof FormDTO;

    field.addEventListener("blur", function () {
      formData[key] = input.value; // Update formData

      // Perform validation on blur for the specific field
      validateForm(input, form);
    });

    // Clear error message and update formData on input
    field.addEventListener("input", function () {
      formData[key] = input.value; // Update formData
      const errorElement = form.querySelector(`#${input.name}Error`);
      if (errorElement) {
        errorElement.textContent = ""; // Clear error for this field
      }
    });
  });
}

// Initialize form submissions for all forms with data-lm-form attribute
function initializeForm() {
  document.querySelectorAll("form[data-lm-form]").forEach((form) => {
    const formId = form.getAttribute("data-lm-form");
    const formElement = form as HTMLFormElement;
    setupFormSubmission(formElement, formUrl + formId);
  });
}

// Show modal function
function showModal(
  title: string,
  message: string,
  link: string,
  buttonText: string
): void {
  const customModal = document.getElementById("customModal") as HTMLElement;
  const modalTitle = document.getElementById("modalTitle") as HTMLElement;
  const modalMessage = document.getElementById("modalMessage") as HTMLElement;
  const modalButton = document.getElementById("modalButton");
  const closeButton = document.getElementById("closeButton");

  customModal.classList.add("flex");
  customModal.classList.remove("hidden");
  modalTitle.textContent = title;
  modalMessage.textContent = message;
  if (modalButton) {
    modalButton.textContent = buttonText;
    modalButton.removeEventListener("click", modalButtonClickHandler);
    modalButton.addEventListener("click", modalButtonClickHandler);
  }
  if (closeButton) {
    closeButton.removeEventListener("click", modalCloseHandler);
    closeButton.addEventListener("click", modalCloseHandler);
  }

  function modalCloseHandler() {
    customModal.classList.add("hidden");
    customModal.classList.remove("flex");
  }

  function modalButtonClickHandler() {
    window.location.href = link;
  }
}

export const formService = {
  initializeForm,
};
