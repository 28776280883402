import getFaq from "./faq";
import { formService } from "./form";
import { LandingSDK } from "./type";

declare global {
  interface Window {
    lm: LandingSDK;
  }
}

window.lm = {
  formService,
  getFaq
};

